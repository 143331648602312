import axiosInstance from '../axiosInstance';

export const PATHS = Object.freeze({
  HARDWARES: '/hardware/assets/',
  GET_BASIC_INFO: (employee_uid: string) => `/employee/${employee_uid}/family-details`,
  PUT_ASSET_DETAILS: `/hardware/employee/`,
  GET_HARDWARE: '/hardware/assets/all',
});

const getEmployeeDetails = async (employee_uid?: string): Promise<any> => {
  const response = await axiosInstance.get(PATHS.GET_BASIC_INFO(employee_uid || ''));
  return response.data;
};

const updateAssetDetails = async (payload: any): Promise<any> => {
  const response = await axiosInstance.post(PATHS.PUT_ASSET_DETAILS, payload);
  return response;
};

const getHardwareList = async (selfAssets: boolean): Promise<any> => {
  const response = await axiosInstance.get(PATHS.HARDWARES, {
    params: { show_all: selfAssets },
  });
  return response;
};
const getAllHardwareList = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.GET_HARDWARE}`);
  return response;
};
const HardwareClient: any = Object.freeze({
  getHardwareList,
  getEmployeeDetails,
  updateAssetDetails,
  getAllHardwareList,
});

export default HardwareClient;
