/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import useAddOffsiteStyles from './AddOffsiteDetailsStyles';
import I18nKey from '../../translations/I18nKey';
import OffsiteClient from '../../api/Offsite/OffsiteAPIs';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';

const ViewOffsiteDetails: React.FC<any> = () => {
  const styles = useAddOffsiteStyles();
  const intl = useIntl();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const [offsiteDetails, setOffsiteDetails] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackbar } = useDisplaySnackbar();

  useEffect(() => {
    setIsLoading(true);
    OffsiteClient.getEmployeeDetails(id)
      .then((res: any) => {
        setOffsiteDetails(res);
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleViewMode = () => {
    navigate(`/offsite/${id}?viewMode=${false}`);
  };
  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Box className={styles.viewFamilyDetailsWrapper}>
        <Box className={styles.viewDetailHeading}>
          <span>
            {intl.formatMessage({
              id: I18nKey.OFFSITE_TITLE,
            })}
          </span>
        </Box>
        <Grid container rowSpacing={2} spacing={1}>
          <Grid item xs={3} />
          <Grid item xs={6}>
            <Paper elevation={3} sx={{ padding: '30px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography sx={{ fontWeight: 'bold' }} fontSize="20px">
                  {offsiteDetails?.employeeFirstName} {offsiteDetails?.employeeLastName}
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', marginY: '20px' }}>
                <Typography>Available for offsite </Typography>
                <Typography sx={{ marginLeft: '10px' }}>
                  {offsiteDetails?.availableForOffsite ? (
                    <TaskAltIcon sx={{ color: 'green' }} />
                  ) : (
                    <CancelOutlinedIcon sx={{ color: 'red' }} />
                  )}
                  {offsiteDetails?.availableForOffsite === null && '-'}
                </Typography>
              </Box>
              {!offsiteDetails?.availableForOffsite && (
                <Box sx={{ display: 'flex', marginY: '20px' }}>
                  <Typography>Reason -</Typography>
                  <Typography sx={{ marginLeft: '10px' }}>
                    {offsiteDetails?.reasonForNotAttending}
                  </Typography>
                </Box>
              )}
              {offsiteDetails?.availableForOffsite && (
                <Box sx={{ display: 'flex', marginY: '20px' }}>
                  <Typography>Need Travel Arrangements </Typography>
                  <Typography sx={{ marginLeft: '10px' }}>
                    {offsiteDetails?.isTravelRequired ? (
                      <TaskAltIcon sx={{ color: 'green' }} />
                    ) : (
                      <CancelOutlinedIcon sx={{ color: 'red' }} />
                    )}
                  </Typography>
                </Box>
              )}
              {offsiteDetails?.availableForOffsite && (
                <Box sx={{ display: 'flex', marginY: '20px' }}>
                  <Typography>Need Accomodation Arrangements </Typography>
                  <Typography sx={{ marginLeft: '10px' }}>
                    {offsiteDetails?.isAccommodationRequired ? (
                      <TaskAltIcon sx={{ color: 'green' }} />
                    ) : (
                      <CancelOutlinedIcon sx={{ color: 'red' }} />
                    )}
                  </Typography>
                </Box>
              )}
              {offsiteDetails?.availableForOffsite && (
                <Box sx={{ display: 'flex', marginY: '20px' }}>
                  <Typography>In Pune or nearby </Typography>
                  <Typography sx={{ marginLeft: '10px' }}>
                    {offsiteDetails?.isNearPune ? (
                      <TaskAltIcon sx={{ color: 'green' }} />
                    ) : (
                      <CancelOutlinedIcon sx={{ color: 'red' }} />
                    )}
                  </Typography>
                </Box>
              )}
              {offsiteDetails?.availableForOffsite && (
                <Box sx={{ display: 'flex', marginY: '20px' }}>
                  <Typography>T-Shirt Size:</Typography>
                  <Typography sx={{ marginLeft: '10px' }}>
                    {offsiteDetails?.tshirtSize || 'Not yet selected'}
                  </Typography>
                </Box>
              )}
            </Paper>
            <Button
              variant="contained"
              type="submit"
              className={styles.submitBtn}
              onClick={handleViewMode}>
              {intl.formatMessage({
                id: I18nKey.BUTTON_LABEL_UPDATE,
              })}
            </Button>
          </Grid>
          <Grid item xs={3} />
        </Grid>
      </Box>
    </>
  );
};

export default ViewOffsiteDetails;
