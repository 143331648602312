import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useHardwareStyles = makeStyles((theme: Theme) => {
  return {
    table: {
      '& .MuiDataGrid-toolbarContainer': {
        padding: '0px !important',
      },
      '& .MuiDataGrid-footerContainer': {
        minHeight: '0px !important',
        borderTop: 'none !important',
        display: 'none !important',
      },
    },
    viewFamilyDetailsWrapper: {
      padding: '1rem',
    },
    viewDetailHeading: {
      textAlign: 'center',
      marginBottom: '1rem',
      fontWeight: 'bold',

      '& span': {
        fontSize: '24px',
      },
    },
    submitBtn: {
      float: 'right',
      marginTop: '15px !important',
    },
    hardwareWrapper: {
      padding: '1rem',
    },
    Header: {
      fontSize: '24px',
      fontWeight: '600',
      paddingBottom: '10px',
    },
    ListWrapper: {
      backgroundColor: theme.palette.appBackground?.lightest,
      width: '100%',

      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },

      '& .poc': {
        padding: '2px 8px',
        marginRight: 5,
        fontSize: 13,
        background: theme.palette.secondary.midDark,
        borderRadius: 12,
        with: '100%',

        '& a': {
          color: 'black',
        },
      },
      '& .poc-cell': {
        flexWrap: 'wrap',
      },
    },
    contentWrapper: {
      display: 'flex',
    },
  };
});

export default useHardwareStyles;
