/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from 'react';
import { Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import Datatable from '../../components/Datatable/Datatable';

import I18nKey from '../../translations/I18nKey';
import { generateRows } from '../../components/Datatable/datatableUtils';
import HardwareClient from '../../api/Hardware/hardwareAPIs';
import useHardwareStyles from './HardwareStyles';
import {
  columns,
  getInitialVisibilityModel,
  hardwareIdName,
  initialShowCols,
} from './HardwareListTableConfig';
import { GlobalContext } from '../../contexts/GlobalContext';

// eslint-disable-next-line react/prop-types
const Hardware: React.FC<any> = ({ viewMode = false }) => {
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showColumns, setShowColumns] = useState([...initialShowCols]);
  const styles = useHardwareStyles();
  const { employeeUid } = useContext(GlobalContext);
  const { showSnackbar } = useDisplaySnackbar();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<Record<string, any>>(
    getInitialVisibilityModel({ showColumns }),
  );
  const intl = useIntl();

  const getTableRows = (data: any) => {
    const formattedData = data.map((el: any, index: number) => ({ key: index, ...el }));
    return generateRows(formattedData, [], [], hardwareIdName);
  };

  const fetchHardwareList = () => {
    setIsLoading(true);
    if (viewMode) {
      HardwareClient.getHardwareList(!viewMode)
        .then((response: any) => {
          const rows = getTableRows(response?.data?.assets);
          setTableRows(rows);
        })
        .catch((e: any) => showSnackbar(e, 'error'))
        .finally(() => setIsLoading(false));
    } else {
      HardwareClient.getAllHardwareList()
        .then((response: any) => {
          const rows = getTableRows(response?.data);
          setTableRows(rows);
        })
        .catch((e: any) => showSnackbar(e, 'error'))
        .finally(() => setIsLoading(false));
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchHardwareList(), [fetch]);

  const onColumnVisibilityModelChange = (newModel: any) => {
    setColumnVisibilityModel(newModel);
  };
  return (
    <>
      <Box className={styles.hardwareWrapper}>
        {!viewMode && (
          <Box className={styles.Header}>
            <span>
              {intl.formatMessage({
                id: I18nKey.HARDWARE_TITLE,
              })}
            </span>
          </Box>
        )}
        <Box className={styles.ListWrapper}>
          <Datatable
            rows={tableRows}
            columns={columns}
            onColumnVisibilityModelChange={onColumnVisibilityModelChange}
            columnVisibilityModel={columnVisibilityModel}
            loading={isLoading}
            showSearch={!viewMode}
            showExportButton={!viewMode}
            columnVisibility={!viewMode}
            exportedFileName={`Assets_List_${dayjs().format('DD_MMMM')}`}
            tableHeight={viewMode ? '54vh' : '76vh'}
            className={viewMode ? styles.table : ''}
            showFilterButton={!viewMode}
            hideFooterPagination={viewMode}
            showRefreshButton={!viewMode}
            getUpdatedList={fetchHardwareList}
          />
        </Box>
      </Box>
    </>
  );
};

export default Hardware;
