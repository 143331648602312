import * as Yup from 'yup';

const addOffsiteDetialsFormValidation = Yup.object().shape({
  available: Yup.string().required('Selecting the availablility field is required'),
  inPune: Yup.string().when('available', (val, schema) => {
    if (val === 'yes') return Yup.string().required('Selecting the location field is required');
    return Yup.string().notRequired();
  }),
  travelRequired: Yup.string().when('available', (val, schema) => {
    if (val === 'yes') return Yup.string().required('Selecting the travel field is required');
    return Yup.string().notRequired();
  }),

  accomodationRequired: Yup.string().when('available', (val, schema) => {
    if (val === 'yes') return Yup.string().required('Selecting the accomodation field is required');
    return Yup.string().notRequired();
  }),
});

const getUpdatedInfo = (data: any) =>
  data.map((v: any) => {
    const { uid, ...rest } = v;
    return uid !== undefined ? { ...rest, uid } : rest;
  });

export { addOffsiteDetialsFormValidation, getUpdatedInfo };
