import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => {
  return {
    addFamilyWrapper: {
      padding: '1rem',
    },
    checkboxWrapper: {
      display: 'flex',
    },
    info: {
      float: 'right',
      width: '18px !important',
      margin: ' 7px 5px 0 -12px ',
    },
    allEmpDependentsWrapper: {
      height: '100%',
      overflowY: 'auto',
      padding: '1rem',
      backgroundColor: theme.palette.appBackground?.lightest,
      '& .textWrapper': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
    },
    Header: {
      marginBottom: '1rem',
      fontSize: '14px',
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'space-between',

      '& span': {
        fontSize: '24px',
      },
      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
      '& .headerTitle': {
        fontSize: '24px',
      },
      '& .headerContent': {
        paddingTop: 5,
      },
    },
    subFormStyle: {
      display: 'flex',
      border: '1px solid #0000001f',
      padding: '25px',
      borderRadius: '5px',
      marginTop: '50px',
      boxShadow: '0px 1px 1px grey',
    },
    btnLabel: {
      padding: '2px 5px',
      marginRight: '10px',
      alignSelf: 'center',
      borderRadius: '3px',
    },
    inverted: {
      '& svg': {
        WebkitTransition: '-webkit-transform 300ms',
        transition: 'transform 300ms',
        WebkitTransform: `rotate(135deg)`,
        transform: `rotate(135deg)`,
      },
    },
    closeBtn: {
      margin: '7px !important',
      '& > span': {
        textTransform: 'none',
      },
    },
    formContainer: {
      minHeight: '450px',
      minWidth: '100%',
      position: 'relative',

      '& .divider-chip': {
        backgroundColor: `rgb(2, 118, 213, 0.1)`,
        color: theme.palette.secondary.light,
      },
    },
    formWrapper: {
      padding: '1rem',
      paddingBottom: '4rem',
      backgroundColor: '#fff',
      '& .upload-button': {
        position: 'absolute',
        bottom: 23,
        right: 23,
      },
    },
    formField: {
      '& input': {
        height: '22px',
      },
    },
    options: {
      listStyleType: 'none',
      position: 'absolute',
      bottom: '55px',
      right: 0,
      width: '225px',
      WebkitTransition: '-webkit-transform 300ms',
      transition: 'transform 300ms',
      paddingLeft: '0 !important',

      '& li': {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '5px',
        margin: '5px',
        borderRadius: 8,
        background: theme.palette.primary.main,
        boxShadow: `0px 0px 8px ${theme.palette.shadow?.main}`,
      },
    },
    checkbox: {
      display: 'flex',
      'align-items': 'center',
    },
    dataBox: {
      position: 'relative',
      border: '1px solid #0000001f',
      padding: '30px',
      borderRadius: '5px',
      marginTop: '10px',
      boxShadow: '0px 1px 1px grey',
      display: 'flex',
    },
    addBtn: {
      '& svg': {
        fontSize: '40px',
      },
    },
    dataRemoveBtn: {
      backgroundColor: `${theme.palette.tertiary?.main} !important`,
      borderRadius: '50% !important',
      padding: 0,
      minWidth: '10px !important',
      top: '-9px',
      right: '-10px',
      fontWeight: 700,

      '& svg': {
        fontSize: '14px',
        color: 'black !important',
      },
    },
    dateFormatInfo: {
      fontSize: '11px',
      color: 'grey',
      marginLeft: '15px',
    },
    deleteBtn: {
      margin: '16px !important',
      top: '0px',
      right: '0px',
      backgroundColor: `${theme.palette.status?.danger} !important`,
      color: '#fff !important',
    },
    dialogTextBox: {
      marginTop: '-15px !important',
    },
    submitBtn: {
      float: 'right',
      marginTop: '15px !important',
    },
    textInfo: {
      fontSize: '14px !important',
    },
    textSubHeadings: {
      fontSize: '12px !important',
    },
    viewFamilyDetailsWrapper: {
      padding: '1rem',
    },
    viewDetailHeading: {
      textAlign: 'center',
      marginBottom: '1rem',
      fontWeight: 'bold',

      '& span': {
        fontSize: '24px',
      },
    },
    table: {
      '& .MuiDataGrid-toolbarContainer': {
        padding: '0px !important',
      },
      '& .MuiDataGrid-footerContainer': {
        minHeight: '0px !important',
        borderTop: 'none !important',
        display: 'none !important',
      },
    },
    uploadText: {
      fontSize: '14px !important',
    },
    pdfIcon: {
      margin: '0 1px 0 8px',
    },
    docIcon: {
      margin: '2px 1px 0 8px',
      fontSize: '1rem !important',
    },
    csvFormWrapper: {
      padding: '1rem',
    },
  };
});
