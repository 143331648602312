import { useContext, useEffect, useRef, useState } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Chip, Divider, Grid, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { AddCircleOutlined, Delete } from '@mui/icons-material';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import DescriptionIcon from '@mui/icons-material/Description';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';
import { GlobalContext } from '../../contexts/GlobalContext';
import useAddAssetsStyles from './AssetDetailsStyles';
import I18nKey from '../../translations/I18nKey';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import HardwareClient from '../../api/Hardware/hardwareAPIs';
import MisDialog from '../../components/MisDialog/MisDialog';

const AddAssetDetails: React.FC<any> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { employeeUid } = useContext(GlobalContext);
  const intl = useIntl();
  const { showSnackbar } = useDisplaySnackbar();
  const styles = useAddAssetsStyles();

  const [putUid, setPutUid] = useState('');
  const [initialValues, setInitialValues] = useState<any>({
    firstName: '',
    lastName: '',
    assets: [{ asset_id: '', file: '' }],
  });
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const location = useLocation();
  const queryParams = queryString.parse(location?.search);
  const [viewMode, setViewMode] = useState(queryParams.viewMode);
  const [openDialog, setOpenDialog] = useState(false);
  const formikRef = useRef<any>();
  const [initialAssetData, setInitialAssetData] = useState<any>([]);

  const getEmployeeDetails = () => {
    setIsLoading(true);
    HardwareClient.getHardwareList(false)
      .then((res: any) => {
        if (res?.data?.assets?.length && viewMode !== 'false') {
          navigate(`/asset/viewMode/${id}`);
        }
        const assets = res?.data?.assets?.map((item: any) => {
          return [{ asset_id: item.assetId, file: { name: item.fileName } }];
        });
        setInitialAssetData(assets.flat());
        const setInitialInfo = {
          firstName: res?.data?.employeeFirstName,
          lastName: res?.data?.employeeLastName,
          assets: assets.flat(),
        };
        setInitialValues({ ...setInitialInfo });
        setPutUid(res.uid);
      })
      .catch((e: any) => {
        showSnackbar(e, 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmit = (values: any) => {
    setIsLoading(true);
    const formData = new FormData();
    const data = values.assets?.map((item: any) => {
      return { asset_id: item.asset_id, file: item.asset_id.concat('-').concat(item.file.name) };
    });
    const payload: any = {
      employee_uid: employeeUid || '',
      assets: data,
    };

    values.assets.map((item: any) => {
      if (item.file instanceof File) {
        const file = new File([item.file], item.asset_id.concat('-').concat(item.file.name), {
          type: item.file.type,
        });
        return formData.append('files', file);
      }
      return item;
    });
    formData.append('emp_hardware_details', JSON.stringify(payload));
    HardwareClient.updateAssetDetails(formData)
      .then((res: any) => {
        showSnackbar(res, 'success');
        setViewMode('');
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getEmployeeDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMode]);

  const addAssetDetialsFormValidation = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    assets: Yup.array().of(
      Yup.object().shape({
        asset_id: Yup.string().matches(/^[0-9]{3}$/, 'Must be exactly 3 digits'),
        file: Yup.mixed()
          .test('fileType', 'Please upload a text file.', function (value) {
            if (!value) {
              return true;
            }
            const acceptedFiles = '.txt';
            const fileExtension = value.name.split('.').pop().toLowerCase();
            return acceptedFiles.includes(`.${fileExtension}`);
          })
          .required('Please upload a file.'),
      }),
    ),
  });
  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Box className={styles.addFamilyWrapper}>
        <Box className={styles.Header}>
          <span className="headerTitle">
            {intl.formatMessage({
              id: I18nKey.ADD_ASSET_TITLE,
            })}
          </span>
        </Box>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={addAssetDetialsFormValidation}
          enableReinitialize
          validateOnMount>
          {(formik) => {
            const { values, setFieldValue, dirty, isValid, handleBlur, errors } = formik;
            return (
              <Form className={styles.formWrapper}>
                <Grid container rowSpacing={2} spacing={1}>
                  <Grid item xs={12}>
                    <Divider>
                      <Chip label="Employee Details" className="divider-chip" />
                    </Divider>
                  </Grid>
                  <Grid item xs={2.4}>
                    <SimpleInputField
                      name="firstName"
                      type="string"
                      label="First Name"
                      size="small"
                      onBlur={(event: any) => {
                        handleBlur(event);
                      }}
                      fullWidth
                      required
                      disabled
                    />
                  </Grid>
                  <Grid item xs={2.4}>
                    <SimpleInputField
                      name="lastName"
                      type="string"
                      label="Last Name"
                      size="small"
                      onBlur={(event: any) => {
                        handleBlur(event);
                      }}
                      fullWidth
                      required
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider>
                      <Chip label="Asset Details" className="divider-chip" />
                    </Divider>
                    <Typography fontSize={13} color="grey">
                      <b> Important:-</b> Asset ID is unique, once Asset ID is added it cannot be
                      changed.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FieldArray
                      name="assets"
                      render={(arrayHelpers) => {
                        return (
                          <>
                            {values.assets?.map((item: any, index: number) => {
                              return (
                                <>
                                  <Box className={styles.dataBox}>
                                    <Grid container rowSpacing={2} spacing={1}>
                                      <Grid item xs={2.3} sx={{ marginTop: '5px' }}>
                                        <SimpleInputField
                                          required
                                          type="string"
                                          name={`assets.${index}.asset_id`}
                                          label="Asset Id"
                                          size="small"
                                          value={item.asset_id}
                                          fullWidth
                                          onBlur={(event: any) => {
                                            handleBlur(event);
                                          }}
                                          disabled={initialAssetData.find(
                                            (v: any) => v.asset_id === item.asset_id,
                                          )}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <SimpleInputField
                                          name={`file`}
                                          label="Upload Document"
                                          variant="standard"
                                          type="file"
                                          onChange={(evt: any) => {
                                            setFieldValue(
                                              `assets.${index}.file`,
                                              evt.currentTarget.files[0],
                                            );
                                          }}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          inputProps={{ accept: '.txt' }}
                                          fullWidth
                                        />
                                        <Box>
                                          {item?.file?.name && (
                                            <Box sx={{ display: 'flex' }}>
                                              <Typography>Uploaded file:</Typography>
                                              {item?.file?.name?.split('.').pop().toLowerCase() ===
                                              'txt' ? (
                                                <TextSnippetIcon
                                                  color="primary"
                                                  className={styles.pdfIcon}
                                                />
                                              ) : (
                                                <DescriptionIcon
                                                  color="primary"
                                                  className={styles.docIcon}
                                                />
                                              )}
                                              <Typography className={styles.uploadText}>
                                                {item?.file?.name}
                                              </Typography>
                                            </Box>
                                          )}
                                        </Box>
                                      </Grid>
                                      <Grid item xs={2}>
                                        {(index !== 0 || values.assets.length > 1) && (
                                          <Button
                                            size="small"
                                            onClick={() => arrayHelpers.remove(index)}>
                                            <Delete color="error" />
                                          </Button>
                                        )}
                                      </Grid>

                                      <Grid item xs={2} />
                                    </Grid>
                                  </Box>
                                </>
                              );
                            })}
                            <Grid item xs={2}>
                              <Button
                                className={styles.addBtn}
                                onClick={() =>
                                  arrayHelpers.push({
                                    asset_id: '',
                                    file: '',
                                  })
                                }>
                                <AddCircleOutlined />
                              </Button>
                            </Grid>
                          </>
                        );
                      }}
                    />
                  </Grid>
                </Grid>
                <Button
                  disabled={!isValid || !dirty}
                  variant="contained"
                  className={styles.submitBtn}
                  onClick={() => setOpenDialog(true)}>
                  {intl.formatMessage({
                    id: I18nKey.BUTTON_LABEL_SUBMIT,
                  })}
                </Button>
              </Form>
            );
          }}
        </Formik>
        <MisDialog
          title="Add Assets"
          message="Are you sure you want to submit? Asset ID once submitted cannot be changed"
          isOpen={openDialog}
          handleSuccess={() => {
            setOpenDialog(false);
            formikRef.current.submitForm();
          }}
          handleClose={() => setOpenDialog(false)}
          actionBtnLabel="Yes"
        />
      </Box>
    </>
  );
};

export default AddAssetDetails;
