import axiosInstance from '../axiosInstance';

export const PATHS = Object.freeze({
  GET_BASIC_INFO: (employee_uid: string) => `/employee/${employee_uid}/offsite/details`,
  GET_ALL_EMPLOYEES_OFFSITE_DETAILS: `/employee/offsite-details/all`,
  SAVE_OFFSITE_DETAILS: (employee_uid: string) => `/employee/${employee_uid}/offsite/details`,
  UPDATE_REMARKS: `/employee/offsite-details/remarks`,
});

const getEmployeeDetails = async (employee_uid?: string): Promise<any> => {
  const response = await axiosInstance.get(PATHS.GET_BASIC_INFO(employee_uid || ''));
  return response.data;
};

const getAllEmpOffsiteDetails = async (): Promise<any> => {
  const response = await axiosInstance.get(PATHS.GET_ALL_EMPLOYEES_OFFSITE_DETAILS);
  return response;
};

const addOffsiteDetails = async (addOffsiteParams: any, employee_uid: string): Promise<any> => {
  const response = await axiosInstance.put(
    PATHS.SAVE_OFFSITE_DETAILS(employee_uid),
    addOffsiteParams,
  );
  return response;
};

const updateRemarks = async (remarks: any): Promise<any> => {
  const response = await axiosInstance.put(PATHS.UPDATE_REMARKS, remarks);
  return response;
};
export interface OffsiteAPIClient {
  readonly getEmployeeDetails: (employee_uid?: string) => Promise<any>;
  readonly getAllEmpOffsiteDetails: () => Promise<any>;
  readonly addOffsiteDetails: (addOffsiteParams: any, employee_uid: string) => Promise<any>;
  readonly updateRemarks: (remarks: any) => Promise<any>;
}

const OffsiteClient: OffsiteAPIClient = Object.freeze({
  getEmployeeDetails,
  getAllEmpOffsiteDetails,
  addOffsiteDetails,
  updateRemarks,
});

export default OffsiteClient;
