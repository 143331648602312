import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import Datatable from '../../components/Datatable/Datatable';
import {
  employeeId,
  dynamicColumns,
  tableRowTotalField,
  columns,
  initialShowCols,
  getInitialVisibilityModel,
} from './AllEmpOffsiteConfig';
import useAddOffsiteStyles from './AddOffsiteDetailsStyles';
import I18nKey from '../../translations/I18nKey';
import { generateRows } from '../../components/Datatable/datatableUtils';
import { GlobalContext } from '../../contexts/GlobalContext';
import OffsiteClient from '../../api/Offsite/OffsiteAPIs';
import { dateFromatter } from '../../utils/utils';

// eslint-disable-next-line react/prop-types
const AllEmpOffsiteDetails: React.FC<any> = ({ viewMode = false }) => {
  const [tableColumns, setTableColumns] = useState<any[]>(columns);
  const { employeeUid } = useContext(GlobalContext);
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const styles = useAddOffsiteStyles();
  const [showColumns, setShowColumns] = useState([...initialShowCols]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<Record<string, any>>(
    getInitialVisibilityModel({ showColumns }),
  );
  const intl = useIntl();
  const { showSnackbar } = useDisplaySnackbar();
  const validateDate = (params: any) => {
    return params && dayjs(params).isValid() ? dateFromatter(params) : '-';
  };
  const getAllEmpOffsiteDetails = () => {
    return OffsiteClient.getAllEmpOffsiteDetails();
  };

  const updateTableValues = (value: any) => {
    if (typeof value === 'boolean') {
      if (value) return 'Yes';
      return 'No';
    }
    return '-';
  };

  const generateDatatableInputs = () => {
    setIsLoading(true);
    getAllEmpOffsiteDetails()
      .then((response) => {
        const transformedData = response.data.map((employee: any) => ({
          ...employee,
          employeeFirstName: `${employee?.employeeFirstName} ${employee?.employeeLastName}`,
          submitted: employee?.submitted ? 'Yes' : 'No',
          availableForOffsite: updateTableValues(employee?.availableForOffsite),
          isNearPune: updateTableValues(employee?.isNearPune),
          isTravelRequired: updateTableValues(employee?.isTravelRequired),
          isAccommodationRequired: updateTableValues(employee?.isAccommodationRequired),
          submittedOn: validateDate(employee.submittedOn),
          updatedOn: validateDate(employee.updatedOn),
          remarks: employee.remarks,
          gender: employee.employeeGender,
          tshirtSize: employee.tshirtSize,
        }));
        const rows = generateRows(transformedData, dynamicColumns, tableRowTotalField, employeeId);

        setTableRows(rows);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };
  const onColumnVisibilityModelChange = (newModel: any) => {
    setColumnVisibilityModel(newModel);
  };

  useEffect(() => {
    generateDatatableInputs();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [fetch]);

  useEffect(() => {
    setTableColumns(columns);
    const updatedTableCols: any = [];
    Object.keys(columnVisibilityModel).forEach((col: string) => {
      if (columnVisibilityModel[col] !== false) updatedTableCols.push(col);
    });

    setShowColumns(updatedTableCols);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [columnVisibilityModel]);

  const handleRowDataChange = (updatedRows: any[]) => {
    setIsLoading(true);
    const updatedRemarks = updatedRows.map((r) => {
      return { uid: r.row.offsiteDetailsUid, remarks: r.row.remarks };
    });
    OffsiteClient.updateRemarks(updatedRemarks)
      .then((result) => {
        showSnackbar(result, 'success');
        getAllEmpOffsiteDetails();
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };
  return (
    <>
      <Box className={styles.allEmpDependentsWrapper}>
        {!viewMode && (
          <Box className={styles.Header}>
            <span>
              {intl.formatMessage({
                id: I18nKey.OFFSITE_TITLE,
              })}
            </span>
          </Box>
        )}
        <Datatable
          editable
          updateRows={handleRowDataChange}
          loading={isLoading}
          rows={tableRows}
          columns={tableColumns}
          showSearch={!viewMode}
          showExportButton={!viewMode}
          columnVisibility={!viewMode}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={onColumnVisibilityModelChange}
          exportedFileName={`Employees_Offsite_Details_List_${dayjs().format('DD_MMMM')}`}
          skipConfirmation
          tableHeight={viewMode ? '54vh' : '76vh'}
          className={viewMode ? styles.table : ''}
          showFilterButton={!viewMode}
          hideFooterPagination={viewMode}
          showRefreshButton={!viewMode}
          getUpdatedList={generateDatatableInputs}
          isCellEditable={(params) => params.row.submitted === 'Yes' && params.field === 'remarks'}
        />
      </Box>
    </>
  );
};

export default AllEmpOffsiteDetails;
