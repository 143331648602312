/* eslint-disable react/prop-types */
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Grid } from '@mui/material';
import useAddAssetStyles from './HardwareStyles';
import I18nKey from '../../translations/I18nKey';
import Hardware from './Hardware';

const ViewAssetDetails: React.FC<any> = () => {
  const styles = useAddAssetStyles();
  const intl = useIntl();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;

  const handleViewMode = () => {
    navigate(`/asset/${id}?viewMode=${false}`);
  };
  return (
    <>
      <Box className={styles.viewFamilyDetailsWrapper}>
        <Box className={styles.viewDetailHeading}>
          <span>
            {intl.formatMessage({
              id: I18nKey.HARDWARE_QUICK_LINK_TITLE,
            })}
          </span>
        </Box>
        <Grid container rowSpacing={2} spacing={1}>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Hardware viewMode />
            <Box sx={{ marginRight: '20px' }}>
              <Button
                variant="contained"
                type="submit"
                className={styles.submitBtn}
                onClick={handleViewMode}>
                {intl.formatMessage({
                  id: I18nKey.BUTTON_LABEL_UPDATE,
                })}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Box>
    </>
  );
};

export default ViewAssetDetails;
